.banner {
  background-image: url(../../../images/background/3.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 5em;
  padding-bottom: 5em;

  .banner-text {
    h2 {
      font-size: 2.5em;
    }
    p {
      letter-spacing: 0.15em;
      font-size: 1.1em;
    }
    margin-left: 4em;
  }
}

.process {
  //   background: #2b313f;
  padding-top: 5em;
  .process-text {
    font-weight: bolder !important;
    font-size: xx-large;
  }
  .process-card {
    padding-bottom: 7em;

    .card:hover {
      background: #253545;
    }
    // @media only screen and (max-width: 350px) {
    //   .card {
    //   }
    // }
    .card {
      width: 27em;
      height: 18em;
      border-radius: 2em;
      //   padding: 3em;
      background: rgba(0, 0, 0, 0.1);
      @media only screen and (max-width: 350px) {
        width: 23em;
      }
      //   :hover {
      //     background: #253545;
      //     width: 27em;
      //     height: 18em;
      //     border-radius: 2em;
      //   }
    }
    i {
      text-align: center;
      font-size: 28px;
      color: #ffffff;
      padding: 14px 0;
      width: 56px;
      height: 56px;
      border-radius: 3px;
      -moz-border-radius: 3px;
      -webkit-border-radius: 3px;
      background-color: #253545;
    }
    .i-circle {
      border-radius: 60px;
    }
  }
}

.journey {
  background: #2b313f;

  .journey-grid {
    padding-top: 3em;
    padding-bottom: 5em;
    .rs-nav-tabs .rs-nav-item.rs-nav-item-active {
      background-color: #fcb34d !important;
      color: #ffffff !important;
    }
  }
}

.count {
  // background: #2b313f;
  padding-top: 4em;
  padding-bottom: 4em;
  .count-grid {
    text-align: left;
    h1 {
      font-size: 3.5em;
      color: #fcb34d;
    }
    p {
      font-weight: bold;
      font-size: large;
    }
  }
}

.disclaimer {
  h1 {
    padding-top: 3em;
  }
  img {
    width: 100%;
  }
}

.faqs {
  // background: #2b313f;
  .faqs-grid {
    padding-top: 7em;
    padding-bottom: 3em;

    h1 {
      text-align: center;
    }
    .manage-faq {
      display: flex;
      justify-content: space-between;
      cursor: pointer;
    }
    .manage-faq:hover {
      color: #fcb34d;
    }
    .dataView {
      line-height: 1.7em;
      letter-spacing: 0.1em;
    }
  }
}
