.terms {
  .body {
    text-align: justify;
    p {
      font-size: 1.05em;
      font-weight: bold;
      letter-spacing: 0.05em;
      padding-top: 0.5em;
      dd {
        margin-left: 2em;
      }
    }
  }
}
