.page {
  background: white;
  display: block;
  margin: 0 auto;
  margin-bottom: 1em;
  box-shadow: 0 0 0.5cm rgba(0, 0, 0, 0.5);
  color: black;
  padding-left: 0.5cm;
  padding-right: 0.5cm;
  width: 8cm;
  height: 11cm;
  word-break: break-all;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: 8cm 11cm;

  .watermark {
    position: absolute;
    top: 25%;
    // left: 25%;
    width: auto;
    height: auto;
    // background-position: center;
    opacity: 0.4;
  }

  .sign {
    // width: 30%;
    // height: 12%;
    display: -webkit-box;
    margin-top: 2em;
  }
}

@media screen and (min-width: 1400px) {
  .page {
    width: 11cm;
    height: 15cm;
    background-size: 11cm 15cm;
  }
}
