.restructure {
    .body {
      p {
        font-size: 16px;
        letter-spacing: 0.05em;
        padding-top: 0.5em;
        dd {
          margin-left: 2em;
        }
      }
    }
    
  }

  .centered-container {
    margin-left: "440px"
  }

  p {
    font-size: 16px;
  }
    
  