.moreFilter {
  position: fixed;
  right: -0.5em;
  top: 9em;
  z-index: 5;
  button {
    padding: 0.5em 1.2em;
  }
  // bottom: 8em;
}

.subFilter {
  position: fixed;
  width: 40%;
  // font-size: 0.1em;
  padding: 1em;
  // border-bottom-right-radius: 2em;
  border-radius: 1em;
  max-height: 50%;
  right: 2em;
  z-index: 6;
  background: rgba(54, 57, 76, 1);
  color: antiquewhite;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.14), 0 4px 8px rgba(0, 0, 0, 0.28);
  transition: all 0.5s ease-out;
  top: 12em;
  padding-bottom: 2em;
  overflow-y: scroll;
  overflow-x: hidden;
}

@media screen and (max-width: 800px) {
  .subFilter {
    width: 37%;
  }
}
@media screen and (max-width: 450px) {
  .subFilter {
    width: 80%;
  }
}
