.moreOption {
  position: fixed;
  right: 1em;
  bottom: 10em;
}
.subMenu {
  // animation: scale 0.2s ease-out;
  // opacity: 0;
  position: fixed;
  width: 40%;
  // font-size: 0.1em;
  padding: 1em;
  // border-bottom-right-radius: 2em;
  border-radius: 1em;
  max-height: 50%;
  right: 1em;
  z-index: 5;
  background: rgba(54, 57, 76, 1);
  color: antiquewhite;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.14), 0 4px 8px rgba(0, 0, 0, 0.28);
  transition: all 0.5s ease-out;
  bottom: 13em;
  padding-bottom: 2em;
  overflow-y: scroll;
  overflow-x: hidden;
  button {
    font-size: 0.8em;
  }
  span {
    font-size: 0.8em;
  }
  @media screen and (max-width: 1300px) {
    button {
      font-size: 0.7em;
    }
  }
}
@keyframes scale {
  0% {
    -webkit-transform: scale(0.4, 0.4);
    transform: scale(0.4, 0.4);
    // opacity: 0.5;
  }
  50% {
    -webkit-transform: scale(0.7, 0.7);
    transform: scale(0.7, 0.7);
  }
  100% {
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}

@media screen and (max-width: 1200px) {
  .subMenu {
    width: 40%;
  }
}

@media screen and (max-width: 800px) {
  .subMenu {
    width: 40%;
  }
}

@media screen and (max-width: 480px) {
  .subMenu {
    width: 60%;
  }
}

@media screen and (max-width: 350px) {
  .subMenu {
    width: 65%;
  }
}
