// .reply {
// .lkjDVC {
//   min-width: 0px !important;
//   max-width: none;
// }
// }
.faqs-grid {
  h1 {
    text-align: center;
  }
  .manage-faq {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
  }
  .manage-faq:hover {
    color: #fcb34d;
  }
}
