.detailsView {
  tbody {
    tr {
      th {
        width: 35ch;
        // min-width: 1ch;
        word-wrap:break-word;
        // font-style: ;
        text-transform: capitalize;
      }
      td{
        word-wrap:break-word;
      }
    }
  }
}

.addButtonLetter {
  display: flex;
  justify-content: flex-end;
  // align-items: end;
  margin-bottom: 1em;
}
