// .sun-editor button > svg {
//   color: #b7c0c0;
// }

// .sun-editor .se-toolbar {
//   background-color: #1f2431;
//   z-index: 1;
// }

// .sun-editor .se-wrapper .se-wrapper-wysiwyg {
//   background-color: #30384b;
//   color: #b7c0c0;
//   font-size: 1.1em !important;
// }

// .sun-editor .se-resizing-bar {
//   background-color: #30384b;
// }

// @import "../../../node_modules/@syncfusion/ej2-base/styles/material.css";
// @import "../../../node_modules/@syncfusion/ej2-buttons/styles/material.css";
// @import "../../../node_modules/@syncfusion/ej2-inputs/styles/material.css";
// @import "../../../node_modules/@syncfusion/ej2-popups/styles/material.css";
// @import "../../../node_modules/@syncfusion/ej2-lists/styles/material.css";
// @import "../../../node_modules/@syncfusion/ej2-navigations/styles/material.css";
// @import "../../../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css";
// @import "../../../node_modules/@syncfusion/ej2-dropdowns/styles/material.css";
// @import "../../../node_modules/@syncfusion/ej2-react-documenteditor/styles/material.css";

.cke_inner {
  .cke_contents {
    min-height: 27em !important;
  }
}

.cke_contents {
  // textarea {
  .cke_source {
    color: black;
  }
  // }
}

.show-variable {
  border: 1px solid white;
  border-radius: 1em;
  padding: 1em;
  .contentVariable {
    margin-top: 2em;
    height: 37em;
    overflow-y: auto;
    p {
      margin-bottom: -0.5em !important;
      color: #b7c0c0;
      margin-right: 0em;
      font-size: 0.8em;
    }
  }
}
