.dispute {
    .body {
      p {
        font-size: 1.05em;
        font-weight: bold;
        letter-spacing: 0.05em;
        padding-top: 0.5em;
        dd {
          margin-left: 2em;
        }
      }
    }
  }
  .headflatline {
    border-top: 2px solid #fcb34d;
  }
  