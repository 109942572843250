.navbar-page {
  .main-header {
    a {
      color: #fff;
    }
    background: #171c25;
    padding-top: 1em;
    padding-bottom: 1em;
    position: sticky;
    top: 0;
    z-index: 5;
    .mobile-header {
      display: flex;
      justify-content: space-between;
      .logo {
        width: 9em;
        @media only screen and (max-width: 350px) {
          width: 8em;
        }
      }
    }
    .mobile-nav-link {
      padding: 1em 3em;
      line-height: 2.5em;
    }
    // .mobile-btn-manage {
    .nav-open-btn {
      font-size: 2em;
      //   padding-right: 0.5em;
      //   position: relative;
      //   top: 0;
      // text-align: right;
      // margin-left: 0.5em;
      margin-top: 0.2em;
    }
    // }
  }
  .custom-btn {
    color: #fff;
    border-radius: 30px;
    -moz-border-radius: 30px;
    -webkit-border-radius: 30px;
    outline: 0;
    font-weight: bold;
    text-decoration: none;
    padding: 8px 30px;
    min-width: 120px;
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 2px;
    background: #fcb34d;
  }
  .main-footer {
    background: #171c25;

    padding-top: 5em;
    .footer-grid {
      h3 {
        font-weight: bold;
      }
      .links {
        font-size: 1.3em;
        line-height: 2.3em;
        a {
          color: rgb(126, 102, 172);
        }
        a:hover {
          color: #fcb34d;
        }
      }
      p {
        font-weight: bold;
        letter-spacing: 0.05em;
      }
    }
    hr {
      width: 100%;
      border: 1px solid #fcb34d;
    }
    .manage-social {
      display: flex;
      justify-content: center;
      margin-top: 2em;
      padding-bottom: 1em;
      h6 {
        font-size: 1.3em;
        margin-top: 0.5em;
      }
      .social-icons {
        a {
          font-size: 2em;
          padding-left: 1em;
          color: #fcb34d;
        }
      }
    }
  }
}
